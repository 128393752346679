import React from 'react';
import './SdvtSevaDetails.css';

function DhanvantriSeva() {
  const regularSevas = [
    { title: 'Archana', price: '₹ 10/-' },
    { title: 'Prathakala Abhisheka (Except Thursday)', price: '₹ 30/-' },
    { title: 'Abhisheka Pradhana (Thursday Only)', price: '₹ 250/-' },
    { title: 'Abhisheka Samuhika (Thursday Only)', price: '₹ 50/-' },
  ];

  const arjithaSevas = [
    { title: 'Nithya Puja (Puduvattu)', price: '₹ 2500/-' },
    { title: 'Daily Archana for 1 year', price: '₹ 3650/-' },
    { title: 'Dhanvantri Utsava Murthy Abhisheka', price: '₹ 1500/-' },
    { title: 'Naivedya Seva (Contact Puja Counter/Office)' },
    { title: 'Vastra Samarpana (Contact Puja Counter/Office)' },
    { title: 'Dhanvantri Jayanthi Sevas (Contact Puja Counter/Office)' },
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Dhanvantri Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {regularSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
      <h3 className="subsection-title">Arjitha Sevas</h3>
      <ul className="seva-list">
        {arjithaSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DhanvantriSeva;
