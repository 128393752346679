import React from 'react';
import './SdvtSevaDetails.css';

function DeviPadmavathiSeva() {
  const regularSevas = [
    { title: 'Kumkumarchane', price: '₹ 15/-' },
    { title: 'Prathakala Abhisheka (Except Tuesday)', price: '₹ 30/-' },
    { title: 'Abhisheka Pradhana (Tuesday Only)', price: '₹ 250/-' },
    { title: 'Samuhika (Tuesday Only)', price: '₹ 50/-' },
    { title: 'Dhanalakshmi Puja Pradhana', price: '₹ 1000/-' },
    { title: 'Dhanalakshmi Puja Samuhika (on Amavasya Day)', price: '₹ 50/-' },
    { title: 'Rahukala Deepa (Sunday Only)', price: '₹ 50/-' },
  ];

  const arjithaSevas = [
    { title: 'Alankara Seva', price: '₹ 2000/-' },
    { title: 'Bangle Alankara', price: '₹ 8000/-' },
    { title: 'Nitya Puja (Puduvattu)', price: '₹ 2500/-' },
    { title: 'Daily Archana for 1 year', price: '₹ 3650/-' },
    { title: 'Naivadya Seva (Contact Puja Counter/Office)' },
    { title: 'Vastra Samarpana (Contact Puja Counter/Office)' },
    { title: 'Lakshmi Jayanthi (Contact Puja Counter/Office)' },
    { title: 'Any Alankara Seva (Contact Puja Counter/Office)'},
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Devi Padmavathi Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {regularSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
      <h3 className="subsection-title">Arjitha Sevas</h3>
      <ul className="seva-list">
        {arjithaSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DeviPadmavathiSeva;
