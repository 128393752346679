import React, { useEffect } from 'react';
import './App.css';
import TitleImage from './Images/sdvt/sdv_templ-1.jpg';
import Temple from './Images/sdvt/sdv_temple.JPG';
import { FaClock } from 'react-icons/fa';
import Notification from './Notification';

function MainContent() {
  useEffect(() => {
    // Load the Facebook SDK
    if (!window.FB) {
      const fbScript = document.createElement('script');
      fbScript.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0";
      fbScript.async = true;
      fbScript.defer = true;
      fbScript.crossOrigin = "anonymous";
      fbScript.onload = () => {
        window.FB.init({
          xfbml: true,
          version: 'v12.0'
        });
        window.FB.XFBML.parse();
      };
      document.body.appendChild(fbScript);
    } else {
      window.FB.XFBML.parse();
    }
  }, []);
  
  return (
    <div className="main-content">
      <img src={TitleImage} alt="Event" className="title-image" />
      <div className="fb-page-container">
        <div className="fb-page"
          data-href="https://www.facebook.com/sdvtemple"
          data-tabs="timeline"
          data-width="400"
          data-height="565"
          data-small-header="false"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true">
        </div>
      </div>
      <div className='content-container'>
        <div>
        <Notification showAdminLogin={false} /> 
        </div>
        <div className="center-container">
          <h1 id="about">Welcome to the Sri Datta Venkateswara Temple</h1>
        </div>
        <div className="temple-image-container">
        <img src={Temple} alt="Event" className='temple-image'/>
        </div>
        <section id="about">
          <h2 className='temple-heading'>About Sri Datta Venkateshwara Temple</h2>
          <p>The Sri Datta Venkateshwara Temple is a sacred place dedicated to the divine and revered presence of Lord Datta Venkateshwara. Located at the heart of our ashram, the temple is a center for spiritual practices and celebrations, embodying the essence of divine grace and devotion.
              Our temple is a focal point for daily rituals and worship. The Sri Chakra puja is performed daily, while special ceremonies like Ganapati Homa are held every Sunday. Major festivals such as Navaratri, Shivaratri, Datta Jayanti, and Sri Swamiji’s birthday are celebrated with grandeur in the Nada Mantapam, accommodating numerous devotees.</p>
          <p>In alignment with Sri Swamiji’s teachings, the temple reflects the universal harmony of all religions. The “Dharma Dhwaja” stupa within the temple grounds symbolizes Yoga, nature’s elements, and the major world religions, highlighting the inclusive spiritual vision of our ashram.
              The Venkateshwara Temple, consecrated in 1999, serves as a venue for worship and spiritual gatherings. Each Friday, when Sri Swamiji is present, worship is performed in this exquisite temple, enhancing the spiritual experience of our visitors.</p>
          <p>Join us in our spiritual journey at the Sri Datta Venkateshwara Temple, where divine grace and harmony guide every ritual and celebration.</p>
        </section>
        <section id="timings" className="timings-section">
          <h2 className='temple-heading'>Temple Timings</h2>
          <div className="timings-container">
            <div className="timing-card">
              <FaClock className="timing-icon"/>
              <h3 className='day'>Weekdays</h3>
              <p className='timings'>6:00 AM - 12:00 PM</p>
              <p className='timings'>5:00 PM - 8:00 PM</p>
            </div>
            <div className="timing-card">
              <FaClock className="timing-icon"/>
              <h3 className='day'>Weekends</h3>
              <p className='timings'>6:00 AM - 12:00 PM</p>
              <p className='timings'>4:30 PM - 8:30 PM</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MainContent;
