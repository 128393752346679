import React from 'react';
import './SdvtSevaDetails.css';

function SachidanandaGanapathySeva() {
  const regularSevas = [
    { title: 'Archana', price: '₹ 10/-' },
    { title: 'Prathakala Abhisheka (Not on Sundays)', price: '₹ 30/-' },
    { title: 'Pradhana Abhisheka (Sunday only)', price: '₹ 250/-' },
    { title: 'Samuhika (Sunday only)', price: '₹ 50/-' },
    { title: 'Sankashta Hara Vratham', price: '₹ 100/-' },
    { title: 'Sankashta Hara Puja', price: '₹ 30/-' },
  ];

  const arjithaSevas = [
    { title: 'Nithya Puja (Puduvattu)', price: '₹ 2500/-' },
    { title: 'Undrallu Alankara (Tambittu Alankara), (Only Sankashti Day) (Vibhuthi Alankara, Termeric, Kumkumalankara & Butter Alankara on request)', price: '₹ 3000/-' },
    { title: 'Daily Archana for 1 year', price: '₹ 3650/-' },
    { title: 'Naivedya Seva (Contact Puja Counter/Office)' },
    { title: 'Vastra Samarpana (Contact Puja Counter/Office)' },
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Sachidananda Ganapathy Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {regularSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
      <h3 className="subsection-title">Arjitha Sevas</h3>
      <ul className="seva-list">
        {arjithaSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SachidanandaGanapathySeva;
