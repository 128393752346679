import React, { useState }  from 'react';
import Modal from 'react-modal';
import './Gallery.css';
import image1 from '../Images/Subrahmanya/subramanya_temple_1.JPG';
import image2 from '../Images/Subrahmanya/subramanya_sideview.jpg';
import image3 from '../Images/Subrahmanya/subramanya_butter.JPG';
import image4 from '../Images/Subrahmanya/subramanya_temple_top.JPG';
import image5 from '../Images/Subrahmanya/GarudaPanchami.jpg';
import image6 from '../Images/Subrahmanya/marakata_subramanya.JPG';
import image7 from '../Images/Subrahmanya/454727638_903885168437527_4072769748489405708_n.jpg';
import image8 from '../Images/Subrahmanya/Navagraha.jpg';
import image9 from '../Images/Subrahmanya/Shani Deepa 2.jpg';
import image10 from '../Images/Subrahmanya/Shani Deepa.jpg';
import image11 from '../Images/Subrahmanya/Naga Mandala.jpg';
import image12 from '../Images/Subrahmanya/Naga Mandala (2).JPG';

Modal.setAppElement('#root');

function MarakathaSubrahmanyaSwamyGallery() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const images = [
    { src: image1, description: 'Marakatha Subrahmanya Swamy Sannidhi' },
    { src: image2, description: 'Marakatha Subrahmanya Swamy Sannidhi' },
    { src: image3, description: 'Butter Alankaram' },
    { src: image4, description: '' },
    { src: image5, description: 'Celebration of Garuda Panchami by adorning the Garuda statue with sandalwood powder' },
    { src: image6, description: '' },
    { src: image7, description: '' },
    { src: image8, description: 'Navagraha' },
    { src: image9, description: 'Shani Deepa' },
    { src: image10, description: 'Shani Deepa' },
    { src: image11, description: 'Naga Mandala' },
    { src: image12, description: 'Naga Mandala' },
  ];

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Daivadarshanam</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(index)}>
            <img src={image.src} alt={image.description} className="gallery-image" />
            <div className="gallery-description">{image.description}</div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">X</button>
        {currentIndex !== null && (
          <>
            <button onClick={handlePrevious} className="modal-prev-arrow"></button>
            <img src={images[currentIndex].src} alt="Modal" className="modal-image" />
            <button onClick={handleNext} className="modal-next-arrow"></button>
          </>
        )}
      </Modal>
    </div>
  );
}

export default  MarakathaSubrahmanyaSwamyGallery;
