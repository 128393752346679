import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './App.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      console.log('Login Request Data:', { email, password });
      const response = await axios.post('https://backend.sdvtemple.in/api/auth/login', {
        email,
        password,
      });
      const { token } = response.data;
  
      const decodedToken = jwtDecode(token);
      const isAdmin = decodedToken.isAdmin;
  
      localStorage.setItem('token', token);
      localStorage.setItem('isAdmin', isAdmin);
  
      navigate('/notifications'); 
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      alert('Login failed. Please check your email and password.');
    }
  };  

/*   const handleRegister = async () => {
    try {
      const response = await axios.post('https://backend.sdvtemple.in/api/auth/register', {
        username,
        email,
        password,
        isAdmin,
      });
      console.log('Registration successful:', response.data);
      alert('Registration successful!');
      setIsRegistering(false); 
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Registration failed');
    }
  }; */

  return (
    <div className="login-register-container">
      <h2>{isRegistering ? 'Register' : 'Login'}</h2>

      {isRegistering ? (
        <div className="form-container">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>
            <input
              type="checkbox"
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
            /> Admin
          </label>
          {/* <button className= 'auth-button' onClick={handleRegister}>Register</button> */}
          <p onClick={() => setIsRegistering(false)} className="toggle-link">Already have an account? Login</p>
        </div>
      ) : (
        <div className="form-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className='auth-button' onClick={handleLogin}>Login</button>
          {/* <p onClick={() => setIsRegistering(true)} className="toggle-link">Don't have an account? Register</p> */}
        </div>
      )}
    </div>
  );
}

export default Login;
