import React from 'react';
import './SdvtSevaDetails.css';

function SdvtSevaDetails() {
  const sevaList = [
    { title: 'Archana', price: '₹ 10/-' },
    { title: 'Prathakala Abhisheka (Monday - Thursday)', price: '₹ 30/-' },
    { title: 'Sahasranama Archana', price: '₹ 30/-' },
    { title: 'Suprabhatha Seva', price: '₹ 50/-' },
    { title: 'Ekantha Seva', price: '₹ 50/-' },
    { title: 'Maha Naivedya', price: '₹ 50/-' },
    { title: 'Rathotsava (Saturdays Only)', price: '₹ 150/-' },
    { title: 'Sri Datta Venkateshwara Abhisheka (Fridays Only)', price: '₹ 500/-' },
    { title: 'Swarna Pushparchana (On Request)', price: '₹ 500/-' },
  ];

  const arjithaSevas = [
    { title: 'Cotton Vastra Samarpana', price: '₹ 13,000/-' },
    { title: 'Peethambara Samarpana (Pure SilkVastra)', price: '₹ 35,000/-' },
    { title: 'Srinivasa Kalyanotsava', price: '₹ 5,000/-' },
    { title: 'Srinivasa Kainkarya', price: '₹ 1,500/-' },
    { title: 'Nitya Puja (Puduvattu)', price: '₹ 2,500/-' },
    { title: 'Poolangi Seva (Thursdays On Request)', price: '₹ 5000/-' },
    { title: 'Udayasthamana Seva (Seva from Sunrise to Sunset)', price: '₹ 5,000/-' },
    { title: 'Daily Archana for 1 year', price: '₹ 3,650/-' },
    { title: 'Naivedya Seva (Contact Puja Counter/Office)' },
    { title: 'Any Vastra Samarpana (Contact Puja Counter/Office)' },
    { title: 'Brahmotsava Seva (Contact Puja Counter/Office)' },
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Sri Datta Venkateshwara Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {sevaList.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
      <h3 className="subsection-title">Arjitha Sevas</h3>
      <ul className="seva-list">
        {arjithaSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SdvtSevaDetails;
