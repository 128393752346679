import React, { useState } from 'react';
import Modal from 'react-modal';
import './Gallery.css';
import image1 from '../Images/Ganapathy/Sachchidananda Ganapati.JPG';
import image2 from '../Images/Ganapathy/Siddhi Vinayaka Gopuram.JPG';
import image3 from '../Images/Ganapathy/Ganesh1.jpg';
import image4 from '../Images/Ganapathy/Vibhuthi.jpg';
import image5 from '../Images/Ganapathy/Sachchidananda Ganapati-1.jpg';
import image6 from '../Images/Ganapathy/Ganapati Shila.jpg';
import image7 from '../Images/Ganapathy/Ganapati Shila 2.jpg';

Modal.setAppElement('#root');

function SachidanandaGanapathyGallery() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const images = [
    { src: image1, description: '' },
    { src: image2, description: 'Siddhi Vinayaka Gopuram' },
    { src: image3, description: 'Sankashta Hara Chathurthi Vratha' },
    { src: image4, description: 'Vibhuti Alankara' },
    { src: image5, description: '' },
    { src: image6, description: 'Ganapathi Shila' },
    { src: image7, description: 'Ganapathi Shila' },
  ];

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Daivadarshanam</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(index)}>
            <img src={image.src} alt={image.description} className="gallery-image" />
            <div className="gallery-description">{image.description}</div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">X</button>
        {currentIndex !== null && (
          <>
            <button onClick={handlePrevious} className="modal-prev-arrow"></button>
            <img src={images[currentIndex].src} alt="Modal" className="modal-image" />
            <button onClick={handleNext} className="modal-next-arrow"></button>
          </>
        )}
      </Modal>
    </div>
  );
}

export default SachidanandaGanapathyGallery;
