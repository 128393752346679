import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function Notification({ showAdminLogin = true }) {
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsLoggedIn(true);
      verifyAdminAccess();
    } else {
      setIsLoggedIn(false);
      setIsAdmin(false);
    }

    fetchNotifications();
  }, []);

  const verifyAdminAccess = async () => {
    try {
      const response = await axios.get('https://backend.sdvtemple.in/api/auth/verify-admin', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.isAdmin) {
        setIsAdmin(true); 
      } else {
        setIsAdmin(false); 
      }
    } catch (error) {
      console.error('Error verifying admin access:', error);
      setIsAdmin(false); 
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://backend.sdvtemple.in/api/notifications');
      const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setNotifications(sortedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleAddNotification = async () => {
    if (!newNotification.trim()) {
      alert('Notification message is required');
      return;
    }

    try {
      const response = await axios.post('https://backend.sdvtemple.in/api/notifications', {
        message: newNotification,
      });
      const newNotif = response.data;
      setNotifications([newNotif, ...notifications]); 
      setNewNotification('');
    } catch (error) {
      console.error('Error adding notification:', error);
      alert('Failed to add notification');
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      await axios.delete(`https://backend.sdvtemple.in/api/notifications/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setNotifications(notifications.filter((notification) => notification._id !== id));
      alert('Notification deleted successfully');
    } catch (error) {
      console.error('Error deleting notification:', error);
      alert(`Failed to delete notification: ${error.response?.data?.message || error.message}`);
    }
  };

  const loginAsAdmin = () => {
    console.log('Redirecting to admin login...');
    window.location.href = '/login';
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); 
  };

  return (
    <div>
      {showAdminLogin && !isLoggedIn && (
        <div className="admin-login-container admin-login-container-right">
          <button
            className="admin-login-button"
            onClick={loginAsAdmin}
          >
            Admin Login
          </button>
        </div>
      )}

      <div className="notification-container">
        <h2 className="notification-title">Notifications</h2>
        <ul className="notification-list">
          {notifications.map((notification, index) => (
            <li key={notification._id} className="notification-item">
              <div className="notification-message">
                {notification.message}
                {index === 0 && <span className="notification-new-label">New</span>}
              </div>
              <div className="notification-timestamp">
                {formatDate(notification.createdAt)}
              </div>
              {isAdmin && (
                <button
                  className="notification-delete-button"
                  onClick={() => handleDeleteNotification(notification._id)}
                >
                  Delete
                </button>
              )}
            </li>
          ))}
        </ul>

        {isAdmin && (
          <div>
            <textarea
              className="notification-textarea"
              value={newNotification}
              onChange={(e) => setNewNotification(e.target.value)}
              placeholder="Add a new notification"
            ></textarea>
            <button
              className="notification-add-button"
              onClick={handleAddNotification}
            >
              Add Notification
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Notification;
