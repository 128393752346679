import React from 'react';
import './SdvtSevaDetails.css';

function MarakathaSubrahmanyaSwamySeva() {
  const regularSevas = [
    { title: 'Archana', price: '₹ 10/-' },
    { title: 'Abhisheka (Daily)', price: '₹ 50/-' },
    { title: 'Abhisheka (Friday)', price: '₹ 500/-' },
    { title: 'Sahasranama Archana', price: '₹ 30/-' },
    { title: 'Kruthika Star Abhisheka Pradhana', price: '₹ 500/-' },
    { title: 'Kruthika Star Abhisheka Samuhika', price: '₹ 50/-' },
    { title: 'Deepalankara Seva (Kruthika Star Day', price: '₹ 50/-' },
    { title: 'Sahsti Day Abhisheka Pradhana', price: '₹ 500/-' },
    { title: 'Sahsti Day Abhisheka Samuhika', price: '₹ 50/-' },
  ];

  const arjithaSevas = [
    { title: 'Kavadi', price: '₹ 300/-' },
    { title: 'Sarva Seva', price: '₹ 2000/-' },
    { title: 'Subrahmanya Kalyana Pradhana', price: '₹ 2000/-' },
    { title: 'Subrahmanya Kalyana Samuhika', price: '₹ 500/-' },
    { title: 'Alankara Seva', price: '₹ 1000/-' },
    { title: 'Naivedya Seva (Contact Puja Counter/Office)' },
    { title: 'Vastra Samarpana (Contact Puja Counter/Office)' },
    { title: 'Ashelsa Bali (On Request)' },
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Marakatha Subrahmanya Swamy Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {regularSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
      <h3 className="subsection-title">Arjitha Sevas</h3>
      <ul className="seva-list">
        {arjithaSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MarakathaSubrahmanyaSwamySeva;
