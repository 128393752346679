import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer" id='footer'>
      <div className="footer-content">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>Please contact the Temple office for more details.</p>
          <p>Email: <a href="mailto:sdvt@dattapeetham.com">sdvt@dattapeetham.com</a> Phone:<strong> (+91) 0821 2483200</strong> </p>
          <p><strong>Address: Sri Datta Venkateswara Temple, Sri Ganapathy Sachchidananda Ashrama, Datta Nagara, Ooty Road, Mysore 570025, India</strong></p>
        </div>
        <div className="footer-links">
          <h2>Explore</h2>
          <ul>
            <li><a href="https://www.dattapeetham.org/">Datta Peetham (Parent Organization)</a></li>
            <li><a href="https://www.dattapeetham.org/donations?ashram_id=6&currency=inr">Donations</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Sri Datta Venkateshwara Temple. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
