import React from 'react';
import './SdvtSevaDetails.css';

function SarvaDoshaHaraShivalayaSeva() {
  const regularSevas = [
    { title: 'Archana', price: '₹ 10/-' },
    { title: 'Abhisheka', price: '₹ 1500/-' },
    { title: 'Abhisheka to Small Linga', price: '₹ 300/-' },
    { title: 'Bilwarchana (Special Request)', price: '₹ 100/-' },
    { title: 'Shaswatha Puja', price: '₹ 11000/-' },
    { title: 'Naivedya Seva (Contact Puja Counter/Office)' },
    { title: 'Vastra Samarpana (Contact Puja Counter/Office)' },
  ];

  return (
    <div className="seva-details-container">
      <h2 className="section-title">Sarva Dosha Hara Shivalaya Seva</h2>
      <h3 className="subsection-title">Regular Sevas</h3>
      <ul className="seva-list">
        {regularSevas.map((seva, index) => (
          <li key={index} className="seva-item">
            <span className="seva-title">{seva.title}</span>
            <span className="seva-price">{seva.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SarvaDoshaHaraShivalayaSeva;
