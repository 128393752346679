import React from 'react';
import './App.css'; // Import the CSS file

export default function Events() {
  return (
    <div className="events-container">
      <section id="events" className="events-section">
        <h2 className="events-title">Upcoming Events</h2>
        <div className="events-iframe-container">
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FKolkata&bgcolor=%23E4C441&src=c2R2dGVtcGxlQGdtYWlsLmNvbQ&color=%23F6BF26"
            className="events-iframe"
            allowFullScreen={true}
            title="Google Calendar"
          />
        </div>
      </section>
    </div>
  );
}
