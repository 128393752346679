import React, { useState } from 'react';
import Modal from 'react-modal';
import './Gallery.css';
import image1 from '../Images/sdvt/sdvt_view_top.png';
import image2 from '../Images/sdvt/dwaram_gopuram.jpg';
import image3 from '../Images/sdvt/svn_gopuram.jpg';
import image4 from '../Images/sdvt/temple2.jpg';
import image5 from '../Images/sdvt/vimana_venkateswara_1.JPG';
import image6 from '../Images/sdvt/night_view.jpg';
import image7 from '../Images/sdvt/GodhaKalyana.jpg';
import image8 from '../Images/sdvt/ananthasayana_2.JPG';
import image9 from '../Images/sdvt/DSC02981.JPG';
import image10 from '../Images/sdvt/DSC02974.JPG';
import image11 from '../Images/sdvt/sarsawathi.JPG';
import image12 from '../Images/sdvt/datta_2.JPG';
import image13 from '../Images/sdvt/SuryaRathothsava.jpg';
import image14 from '../Images/sdvt/SilverJubliee.jpg';
import image15 from '../Images/sdvt/Title.jpeg';
import image16 from '../Images/sdvt/Vikunta.jpg';
import image17 from '../Images/sdvt/Dhwaja Stambha.jpg';
import image18 from '../Images/sdvt/namakoti mandapa.JPG';
import image19 from '../Images/sdvt/navagraha_1.JPG';
import image20 from '../Images/sdvt/sila datta_1.jpg';
import image21 from '../Images/sdvt/painting_1.JPG';
import image22 from '../Images/sdvt/rathotsava.JPG';
import image23 from '../Images/sdvt/sahasrapadma_dasavataras.JPG';
import image24 from '../Images/sdvt/sahasrapadmam.JPG';
import image25 from '../Images/sdvt/Valmeeka_Venkatesha.JPG';
import image26 from '../Images/sdvt/krishna_1.JPG';
import image27 from '../Images/sdvt/varahaswamy.jpg';
import image28 from '../Images/sdvt/Crowds.jpg';

Modal.setAppElement('#root'); 

function SdvtGallery() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const images = [
    { src: image1, description: 'The Towering Gopuram of Sri Datta Venkateshwara Temple ' },
    { src: image2, description: 'Dwaram Gopuram' },
    { src: image3, description: '' },
    { src: image4, description: 'Majestic Architecture of Sri Datta Venkateshwara Temple ' },
    { src: image5, description: 'Vimana Venkateswara' },
    { src: image6, description: '' },
    { src: image7, description: '' },
    { src: image8, description: 'Anantasayana'},
    { src: image9, description: '' },
    { src: image10, description: '' },
    { src: image11, description: 'Saraswathi' },
    { src: image12, description: '' },
    { src: image13, description: 'Surya Rathotsava celebration' },
    { src: image14, description: 'A glimpse of the Silver Jubilee celebration' },
    { src: image15, description: '' },
    { src: image16, description: '' },
    { src: image17, description: '' },
    { src: image18, description: 'Namakoti Mantapa' },
    { src: image19, description: 'Sila Datta' },
    { src: image20, description: '' },
    { src: image21, description: '' },
    { src: image22, description: 'Rathotsava' },
    { src: image23, description: 'Sahasrapadma Dasavataras' },
    { src: image24, description: 'Sahasrapadmam' },
    { src: image25, description: 'Valmeeka Venkatesha' },
    { src: image26, description: 'Krishna' },
    { src: image27, description: 'Varahaswamy' },
    { src: image28, description: 'Varahaswamy' },
  ];

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Daivadarshanam</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(index)}>
            <img src={image.src} alt={image.description} className="gallery-image" />
            <div className="gallery-description">{image.description}</div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">X</button>
        {currentIndex !== null && (
          <>
            <button onClick={handlePrevious} className="modal-prev-arrow"></button>
            <img src={images[currentIndex].src} alt="Modal" className="modal-image" />
            <button onClick={handleNext} className="modal-next-arrow"></button>
          </>
        )}
      </Modal>
    </div>
  );
}

export default SdvtGallery;
