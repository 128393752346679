import React, { useState } from 'react';
import Modal from 'react-modal';
import './Gallery.css';
import image1 from '../Images/Dhanvantri/Dhanvantari.jpg';

Modal.setAppElement('#root');

function DhanvantariGallery() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const images = [
    { src: image1, description: '' },
  ];

  const openModal = (image) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Daivadarshanam</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(image.src)}>
            <img src={image.src} alt={image.description} className="gallery-image" />
            <div className="gallery-description">{image.description}</div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button onClick={closeModal} className="modal-close-button">X</button>
        {modalImage && <img src={modalImage} alt="Modal" className="modal-image" />}
      </Modal>
    </div>
  );
}

export default DhanvantariGallery;
